<template>
    <span>
        <SendLabResultModal 
            :order_id="this.order_id"
            :result_id="this.result_id"
            :email_addresses="this.email_addresses"
            :order_number="this.order_number"
            :result_number="this.result_number"
            :redirect="redirect"
        />
        <a 
            variant="success" 
            class="btn btn-light btn-xs mr-2"
            @click="openModal()"
        >
            <slot></slot>
        </a>
    </span>
</template>

<script>
import SendLabResultModal from './modal/SendLabResultModal.vue';

export default {
    name: "SendLabResultButton",
    components: {
        SendLabResultModal
    },
    props: [
        "order_id", 
        "result_id", 
        "email_addresses",
        "order_number",
        "result_number",
        "redirect"
    ],
    methods: {
        openModal() {
            this.$bvModal.show("modal-sendlab-" + this.order_id + "-" + this.result_id); 
        }
    }
}
</script>