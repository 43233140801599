<template>
	<div>
		<div class="card card-custom card-stretch gutter-b">
			<div class="card-body pt-3 pb-0">
	      <b-collapse visible id="collapse-filter">
	        <div class="row mb-3">
		        <div class="col-lg-3">
		        	<b-form-group label="Nazwa zleceniodawcy" label-for="name">
	              <b-form-input
	                id="name"
	                placeholder="Podaj nazwę"
	                v-model="filters.name"
	              ></b-form-input>
	            </b-form-group>
		        </div>
		        <div class="col-lg-3">
		        	<b-form-group label="Numer zlecenia" label-for="number">
	              <b-form-input
	                id="number"
	                placeholder="Podaj numer zlecenia"
	                v-model="filters.number"
	              ></b-form-input>
	            </b-form-group>
		        </div>
		        <div class="col-lg-3">
		        	<b-form-group
		        	  label="Data złożenia zlecenia"
		        	  label-for="orderDate"
		        	>
		        	  <b-form-datepicker
		        	    reset-button
		        	    type="date"
		        	    placeholder="dd.mm.rrrr"
		        	    size="md"
		        	    :start-weekday="1"
		        	    :date-format-options="{
		        	      year: 'numeric',
		        	      month: 'numeric',
		        	      day: 'numeric',
		        	    }"
		        	    id="orderDate"
		        	    v-model="filters.date"
		        	  >
		        	  </b-form-datepicker>
		        	</b-form-group>
		        </div>
		        <div class="col-lg-3">
		        	<b-form-group label="Matryca" label-for="matrix">
              <b-form-select
                id="matrix"
                v-model="filters.matrix"
                multiple
                :options="matrixList"
              ></b-form-select>
            </b-form-group>
		        </div>
	        </div>
	  		</b-collapse>
			</div>
		</div>

		<div class="table-responsive">
      <v-data-table
        dense
        :headers="computedHeaders"
        :items="dataToShow"
        class="table custom-table table-head-custom table-vertical-center table-head-bg table-borderless"
        item-key="id"
        mobile-breakpoint="0"
        :footer-props="{
          showFirstLastPage: true,
          itemsPerPageText: 'Wyników na stronę',
          pageText: '{0}-{1} na {2}',
          itemsPerPageOptions: [10, 30, 50, 100]
        }"
        :items-per-page="50"
      >
      	<template v-slot:body="{ items, headers }">
      		<tbody>
      			<template v-for="(order, index) in items">
      				<tr
      					:key="order.id"
      					:data-index="index"
      				>
      					<td>
      						<span class="text-dark-75 d-block font-size-md">
      							<span v-if="order.status == 'draft'" style="color: red;">
      								SZKIC &nbsp; 
      							</span>
      							{{ order.client.name }}
      						</span>
      					</td>
      					<td>
      						<span class="text-dark-75 d-block font-size-md">
      							{{ order.number }}
      						</span>
      					</td>
      					<td>
      						<span class="text-dark-75 d-block font-size-md">
      							{{ 
  									order.samples.map( (s, i) => {
	                                let num = order.number.split('/');
	                                num[0] += "-" + ( i + 1 );

	                                return num.join('/');
                              } ).join(', ')
      							}}
      						</span>
      					</td>
      					<td>
      						<span class="text-dark-75 d-block font-size-md">{{ order.createdAt }}</span>
      					</td>
      					<td><span class="text-dark-75 d-block font-size-md">{{ order.matrix }}</span></td>
      					<td>
      						<a
      						    class="btn btn-light btn-xs mr-2"
      						    title="Edytuj wpis"
      						    @click="editLabOrder(order.id, order.client.id)"
      						>
      						  <i class="fa fa-edit p-0 text-primary"></i>
      						</a>

							<a
								class="btn btn-light btn-xs mr-2"
								title="Usuń wpis"
								@click="removeLabOrder(order.id)"
								v-if="order.status == 'draft'"
							>
								<i class="fa fa-trash p-0 text-primary"></i>
							</a>

							<a
								class="btn btn-light btn-xs mr-2"
								title="Dodaj kartę badania"
								@click="addChart(order.id)"
							>
								<i class="fa fa-plus p-0 text-primary"></i>
							</a>

							<b-button-group v-if="order.status != 'draft'">
								<b-dropdown
								no-caret
								variant="outline"
								class="bg-light mr-2"
								>
								<template #button-content>
									<span class="m-0">
									<i
										class="fas fa-print p-0 text-primary"
									></i>
									</span>
								</template>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'broilery')">
									Broilery
									</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'drob_nieobjety')">
									Drób nieobjęty programem
									</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'indyk_hodowlany')">
									Indyk hodowlany
									</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'indyk_rzezny')">
									Indyk rzeźny
									</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'kury_nioski')">
									Kury nioski
									</a>
								</b-dropdown-item>
								<b-dropdown-item>
									<a class="" @click="printLabOrder(order.id, 'kury_reprodukcja')">
									Kury reprodukcja
									</a>
								</b-dropdown-item>
								</b-dropdown>
							</b-button-group>      

							<b-button
								type="button"
								@click="handleCollapseSO(order.id)"
								class="btn btn-light btn-xs"
							>
								<template v-if="isSOListVisible(order.id)">
								<i class="fa fa-angle-up p-0 text-primary"></i>
								</template>
								<template v-else>
								<i class="fa fa-angle-down p-0 text-primary"></i>
								</template>
							</b-button>						
      					</td>

						<td>
							<a
								class="btn btn-light btn-xs mr-2"
								title="Dodaj wynik badania"
								@click="labOrderResult(order.id)"
								v-if="order.status != 'draft'"
							>
								<i class="fa fa-plus p-0 text-primary"></i>
							</a>

							<b-button
								type="button"
								@click="hadnleCollapseRes(order.id)"
								class="btn btn-light btn-xs"
								v-if="order.status != 'draft'"
							>
								<template v-if="isResListVisible(order.id)">
									<i class="fa fa-angle-up p-0 text-primary"></i>
								</template>
								<template v-else>
									<i class="fa fa-angle-down p-0 text-primary"></i>
								</template>
							</b-button>
						</td>
      				</tr>
      				<tr :key="index + 10000">
      				  <td class="bg-white" colspan="12">
      				    <b-collapse
      				      :visible="isSOListVisible(order.id)"
      				      class="mt-2"
      				    >
      				      <table
      				        class="table table-vertical-center table-noradius table-sm"
      				      >
      				        <thead>
      				          <tr
      				            class=""
      				            style="border-radius: 0 !important;"
      				          >
      				            <th class="col-1">Numer próbki</th>
      				            <th class="col-1">Opis</th>
      				            <th class="col-1">Kieurnki badań</th>
      				            <th class="col-1">Pokaż karty badań</th>
      				          </tr>
      				        </thead>
      				        <tbody>
      				        	<template
      				        		v-for="sample in order.samples"
      				        	>
      				        		<tr
      				        			:key="sample.id"
      				        			class=" cursor-pointer text-hover-primary bg-secondary"
      				        		>
      				        			<td>{{ order.number.split('/')[0] + '-' + sample.index + '/' + order.number.split('/').splice(1, 4).join('/') }}</td>
      				        			<td>{{ sample.desc }}</td>
      				        			<td>
      				        				{{ sample.research.map( el => getResearchLineLabel(el) ).join(', ') }}
      				        			</td>
      				        			<td>
      				        				<b-button
      				        				  type="button"
      				        				  @click="handleCollapseSample(order.number.split('/')[0] + '-' + sample.index + '/' + order.number.split('/').splice(1, 4).join('/'))"
      				        				  class="btn btn-light btn-xs"
      				        				>
      				        				  <template v-if="isSampleListVisible(order.number.split('/')[0] + '-' + sample.index + '/' + order.number.split('/').splice(1, 4).join('/'))">
      				        				    <i class="fa fa-angle-up p-0 text-primary"></i>
      				        				  </template>
      				        				  <template v-else>
      				        				    <i class="fa fa-angle-down p-0 text-primary"></i>
      				        				  </template>
      				        				</b-button>	
      				        			</td>
      				        		</tr>

      				        		<tr
      				        			:key="sample.id + 10000 + 's'"
      				        		>
      				        			<td class="bg-white" colspan="12">
      				        			  <b-collapse
      				        			    :visible="isSampleListVisible(order.number.split('/')[0] + '-' + sample.index + '/' + order.number.split('/').splice(1, 4).join('/'))"
      				        			    class="mt-2"
      				        			  >
      				        			  	<table>
      				        			  		<thead>
      				        			  			<tr>
      				        			  				<th>Próbki uwzględnione w karcie</th>
      				        			  				<th>Kierunek badania</th>
      				        			  				<th>Data utworzenia</th>
      				        			  				<th>Akcja</th>
      				        			  			</tr>
      				        			  		</thead>
      				        			  		<tbody>
      				        			  			<template 
      				        			  				v-for="card in getSampleCards( order.number.split('/')[0] + '-' + sample.index + '/' + order.number.split('/').splice(1, 4).join('/') )"
      				        			  				>
      				        			  				<tr :key="card.id + 10000 + 'ab'">
      				        			  					<td>{{ card.samples.join(' | ') }}</td>
      				        			  					<th>{{ card.researchLine }}</th>
      				        			  					<td>{{ card.created_at.date.split(' ')[0] }}</td>
      				        			  					<td>
      				        			  						<a
      				        			  						    class="btn btn-light btn-xs mr-2"
      				        			  						    title="Edytuj wpis"
      				        			  						    @click="editChart(card.id, order.id)"
      				        			  						>
      				        			  						  <i class="fa fa-edit p-0 text-primary"></i>
      				        			  						</a>
      				        			  					</td>
      				        			  				</tr>
      				        			  			</template>
      				        			  		</tbody>
      				        			  	</table>
	      				        			</b-collapse>
	      				        		</td>
      				        		</tr>
      				        	</template>
      				        </tbody>
      				      </table>
      				    </b-collapse>
      				  </td>
      				</tr>
					<tr :key="index + 'res'">
						<td class="bh-white" colspan="12">
							<b-collapse
								:visible="isResListVisible(order.id)"
								class="mt-2"
							>
								<table
									class="table table-vertical-center table-noradius table-sm"
								>
									<tr>
										<td>Numer</td>
										<td>Próba podwójna</td>
										<td>Próba zbiorcza</td>
										<td>Data utworzenia</td>
										<td class="col-2">Akcje</td>
									</tr>
									<tr
										v-for="(result, index) in getOrderResult(order.id)"
										v-bind:key="'resindex'+index"
									>
										<td>{{ result.number }}</td>
										<td>{{ result.double }}</td>
										<td>{{ result.group }}</td>
										<td>{{ result.date_created.split(' ')[0] }}</td>
										<td>
											<a
												class="btn btn-light btn-xs mr-2"
												title="Edytuj wynik badania"
												@click="labOrderResultEdit(result.id, order.id)"
												v-if="order.status != 'draft'"
											>
												<i class="fa fa-edit p-0 text-primary"></i>
											</a>

											<!-- Dodaj przycisk wydruku tutaj -->
											<a
												class="btn btn-light btn-xs mr-2"
												title="Drukuj wynik badania"
												@click="labOrderResultPrint(result.id)"
												v-if="result.accepted != 'true'"
											>
												<i class="fas fa-print p-0 text-primary"></i>
											</a>

											<SendLabResultButton
												:order_id="order.id"
												:result_id="result.id"
												:order_number="order.number"
												:result_number="result.number"
												:email_addresses="[...order.content.receiverEmails.additional, ...order.content.receiverEmails.basic]"
												v-if="result.accepted != 'true' && result.result_sent != 'yes'"
											>
												<i class="fas fa-envelope p-0 text-primary"></i>
											</SendLabResultButton>

											<a
												class="btn btn-light btn-xs mr-2"
												title="Usuń wynik badania"
												@click="labOrderResultDelete(result.id, order.id)"
												v-if="result.accepted != 'true'"
											>
												<i class="fa fa-trash p-0 text-primary"></i>
											</a>
										</td>
									</tr>
								</table>	
							</b-collapse>
						</td>
					</tr>
      			</template>
      		</tbody>
      	</template>
    	</v-data-table>   
		</div>
	</div>
</template>

<script>
	import {
	  CLIENT_LIST,
	} from "@/core/services/store/client.module";
	import { mapGetters } from "vuex";
	import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
	import Swal from "sweetalert2";
	import SendLabResultButton from "../../content/SendLabResultButton.vue";

	export default {
		name: "orderPanelLabListing",
		components: {SendLabResultButton},
		mounted() {
			this.$store.dispatch("orderLabList");
			this.$store.dispatch(CLIENT_LIST);
			this.$store.dispatch("researchLinesList");
			this.$store.dispatch("getLabCharts");

			this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Panel laboratorium - Lista zleceń laboratoryjnych", route: "orderPanelLab" }
    ]);
		},
		data() {
			return {
				filters: {
					name: "",
					number: "",
					date: "",
					matrix: []
				},
				openOrders: [],
				openSamples: [],
				openResults: [],
				// researchLines: [
				// 	{
				// 	  id: "serologia_ae",
				// 	  label: "AE",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "serologia_cav",
				// 	  label: "CAV",
				// 	},
				// 	{
				// 	  id: "serologia_fadv1",
				// 	  label: "FAdV1",
				// 	},
				// 	{
				// 	  id: "serologia_ibd",
				// 	  label: "IBD",
				// 	},
				// 	{
				// 	  id: "serologia_ibv",
				// 	  label: "IBV",
				// 	},
				// 	{
				// 	  id: "serologia_msmg",
				// 	  label: "MS/MG",
				// 	},
				// 	{
				// 	  id: "serologia_ndv",
				// 	  label: "NDV",
				// 	},
				// 	{
				// 	  id: "serologia_ort",
				// 	  label: "ORT",
				// 	},
				// 	{
				// 	  id: "serologia_reo",
				// 	  label: "REO",
				// 	},
				// 	{
				// 	  id: "weterynaria_anatomo-patologiczne",
				// 	  label: "Badanie anatomo-patologiczne",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "weterynaria_pobranie_materialu",
				// 	  label: "Pobranie materiału",
				// 	},
				// 	{
				// 	  id: "weterynaria_badanie_bakteriologiczne_4",
				// 	  label: "Badanie bakteriologiczne (4 kierunki)",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "weterynaria_antybiogram",
				// 	  label: "Antybiogram - metoda dyfuzyjno-krążkowa",
				// 	},
				// 	{
				// 	  id: "weterynaria_badanie_mykologiczne",
				// 	  label: "Badanie mykologiczne",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "weterynaria_salmonella_spp",
				// 	  label: "Obecność Salmonella spp.",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "weterynaria_parazytologiczne_willysa",
				// 	  label:
				// 	    "Badanie parazytologiczne ,metoda mikroskopowa z flotacją wg Willysa",
				// 	  isNew: false,
				// 	},
				// 	{
				// 	  id: "pasza_1",
				// 	  label:
				// 	    "Obecnosć pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 +A1:2020-09"
				// 	},
				// 	{
				// 	  id: "pasza_2",
				// 	  label:
				// 	    "Oznaczanie ogólnej liczby drobnoustrojów w 30'C PN-EN ISO 4833-1:2013-12"
				// 	},
				// 	{
				// 	  id: "pasza_3",
				// 	  label:
				// 	    "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017-08"
				// 	},
				// 	{
				// 	  id: "pasza_4",
				// 	  label:
				// 	    "Oznaczanie liczby Clostridium perfringens PN-EN ISO 7937:2005"
				// 	},
				// 	{
				// 	  id: "pasza_5",
				// 	  label:
				// 	    "Oznaczanie liczby drożdżzy i pleśni PN-R-64791:1994"
				// 	},
				// 	{
				// 	  id: "pasza_6",
				// 	  label:
				// 	    "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
				// 	},
				// 	{
				// 	  id: "pasza_7",
				// 	  label:
				// 	    "Obecności Clostridium perfringens PN-EN ISO 7937:2005/ PN-R-64791:1994"
				// 	},
				// 	{
				// 	  id: "pasza_8",
				// 	  label:
				// 	    "Obecności beztlenowych laseczek przetrwalnikujących PN-R-64791:1994 Pkt.3.3.4.1"
				// 	},
				// 	{
				// 	  id: "pasza_9",
				// 	  label:
				// 	    "Oznaczenie NPL Enterobacteriaceae PN-R-64792:1997"
				// 	},
				// 	{
				// 	  id: "pasza_10",
				// 	  label:
				// 	    "Oznaczenie NPL bakterii z grupy coli PN-ISO 483:2007"
				// 	},
				// 	{
				// 	  id: "pasza_11",
				// 	  label:
				// 	    "Oznaczenie NPL Escherichia coli PN-EN ISO 16649-3:2015-07"
				// 	},
				// 	{
				// 	  id: "pasza_12",
				// 	  label:
				// 	    "Oznaczenie liczby Escherichia coli PN-ISO 16649-2:2004"
				// 	},
				// 	{
				// 	  id: "pasza_13",
				// 	  label:
				// 	    "Oznaczenie liczby Staphylococcus aureus PN-EN ISO 6888-1:2001+A1:2004+A2:2018-10"
				// 	},
				// 	{
				// 	  id: "pasza_14",
				// 	  label:
				// 	    "Inne"
				// 	},
				// 	{
				// 	  "id": "zywnosc1",
				// 	  "label": "Obecność pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 + A1:2020-09"
				// 	},
				// 	{
				// 	  "id": "zywnosc2",
				// 	  "label": "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
				// 	},
				// 	{
				// 	  "id": "zywnosc3",
				// 	  "label": "Obecności Cronobacter spp. PN-EN ISO 22964:2017-06"
				// 	},
				// 	{
				// 	  "id": "zywnosc4",
				// 	  "label": "Obecność gronkowców koagulazo-dodatnich. PN-EN ISO 6888-3:2004+AC:2005"
				// 	},
				// 	{
				// 	  "id": "zywnosc5",
				// 	  "label": "Obecność bakterii z rodziny Enterobacteriaceae PN-ISO 21528-1:2017-08"
				// 	},
				// 	{
				// 	  "id": "zywnosc6",
				// 	  "label": "Oznaczanie ogólnej liczby drobnoustrojów PN-EN ISO 4833-1:2013-12"
				// 	},
				// 	{
				// 	  "id": "zywnosc7",
				// 	  "label": "Oznaczanie mezofilnych bakterii fermentacji mlekowej PN-ISO 15214:2002"
				// 	},
				// 	{
				// 	  "id": "zywnosc8",
				// 	  "label": "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017"
				// 	},
				// 	{
				// 	  "id": "zywnosc9",
				// 	  "label": "Oznaczanie bakterii z grupy coli PN-ISO 4832:2007"
				// 	},
				// 	{
				// 	  "id": "zywnosc10",
				// 	  "label": "Oznaczanie liczby β-glukoronidazo-dodatnich Escherichia coli. PN-ISO 16649-2:2004"
				// 	},
				// 	{
				// 	  "id": "zywnosc11",
				// 	  "label": "Oznaczanie liczby Bacillus cereus PN-EN ISO 7932:2005"
				// 	},
				// 	{
				// 	  "id": "zywnosc12",
				// 	  "label": "Oznaczanie liczby drożdży i pleśni PN-ISO 21527-1:2009, PN-ISO 21527-2:2009, PN-ISO 6611:2007"
				// 	},
				// 	{
				// 	  "id": "zywnosc13",
				// 	  "label": "Oznaczanie liczby Listera monocytogenes PN-EN ISO 11290-2:2017-07"
				// 	},
				// 	{
				// 	  "id": "zywnosc14",
				// 	  "label": "Oznaczanie liczby Listeria spp. PN-EN ISO 11290-2:2017-07"
				// 	},
				// 	{
				// 	  "id": "zywnosc15",
				// 	  "label": "Oznaczanie liczbygronkowców koagulazo-dodatnich PN-EN ISO 6888-1:2022-03"
				// 	},
				// 	{
				// 	  "id": "zywnosc16",
				// 	  "label": "Inne"
				// 	},
				// 	{
				// 	  id: "woda1",
				// 	  label: "Ogólna liczba drobnustrojów w 22ºC PN-EN ISO 6222 A"
				// 	},
				// 	{
				// 	  id: "woda2",
				// 	  label: "Ogólna liczba drobnustrojów w 36ºC PN-EN ISO 6222 A"
				// 	},
				// 	{
				// 	  id: "woda3",
				// 	  label: "Oznaczanie liczby enterokoków kałowych PN-EN ISO 7889-2 A"
				// 	},
				// 	{
				// 	  id: "woda4",
				// 	  label: "Oznaczanie ilościowe Escherichia coli i bakterii grupy coli PN-EN ISO 9308-1 A"
				// 	},
				// 	{
				// 	  id: "woda5",
				// 	  label: "Wykrywanie i oznaczanie ilościowe Pseudomonas aeruginosa PN-EN ISO 16266 A"
				// 	},
				// 	{
				// 	  id: "woda6",
				// 	  label: "Oznaczanie ilościowe Clostridium perfringens PN-EN ISO 14189 A"
				// 	},
				// 	{
				// 	  id: "woda7",
				// 	  label: "Obecność pałeczek Salmonella spp. PN-EN ISO 6579-1:2017-04 + A1:2020-09"
				// 	},
				// 	{
				// 	  id: "woda8",
				// 	  label: "Inne"
				// 	},
				// 	{
				// 	  id: "srodowisko1",
				// 	  label: "Obecność pałeczek Salmonella spp. (środowisko produkcji) PN-EN ISO 6579-1:2017-04 + A1:2020-09"
				// 	},
				// 	{
				// 	  id: "srodowisko2",
				// 	  label: "Obecność pałeczek Salmonella spp. (produkcja pierwotna) PN-EN ISO 6579-1:2017-04 + A1:2020-09"
				// 	},
				// 	{
				// 	  id: "srodowisko3",
				// 	  label: "Obecności Listeria monocytogenes PN-EN ISO 11290-1:2017"
				// 	},
				// 	{
				// 	  id: "srodowisko4",
				// 	  label: "Obecność bakterii z rodziny Enterobacteriaceae PN-ISO 21528-1:2017"
				// 	},
				// 	{
				// 	  id: "srodowisko5",
				// 	  label: "Oznaczanie ogólnej liczby drobnoustrojów PN-EN ISO 4833-1:2013-12"
				// 	},
				// 	{
				// 	  id: "srodowisko6",
				// 	  label: "Oznaczanie liczby bakterii z rodziny Enterobacteriaceae PN-ISO 21528-2:2017"
				// 	},
				// 	{
				// 	  id: "srodowisko7",
				// 	  label: "Oznaczanie liczby drożdży i pleśni PN-ISO 21527-2:2007"
				// 	},
				// 	{
				// 	  id: "srodowisko8",
				// 	  label: "Inne"
				// 	},
				// 	{
				// 	  id: "urzedowe1",
				// 	  label: "Obecność pałeczek Salmonella spp. (pojedyncza) PN-EN ISO 6579-1:2017-04 + A1:2020-09 +ISO/TR 6579-3:2014"
				// 	},
				// 	{
				// 	  id: "urzedowe2",
				// 	  label: "Obecność pałeczek Salmonella spp. (podwójna) PN-EN ISO 6579-1:2017-04 + A1:2020-09+ ISO/TR 6579-3:2014"
				// 	}
				// ]
			}
		},
		computed: {
		  ...mapGetters([
		    "clients",
		    "labOrders",
		    "researchLinesRaw",
		    "charts",
			"labResults"
		  ]),
		  computedHeaders() {
		  	return [
		  		{
		  		  text: "Nazwa firmy lub Imię i Nazwisko klienta",
		  		  value: "clientId",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Numer zlecenia",
		  		  value: "number",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Numery próbek",
		  		  value: "samples",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Data zlecenia",
		  		  value: "createdAt",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Matryca",
		  		  value: "matrix",
		  		  style: "min-width: 200px;"
		  		},
		  		{
		  		  text: "Akcje",
		  		  value: "actions",
		  		  style: "min-width: 200px;"
		  		},
				{
		  		  text: "Wyniki",
		  		  value: "results",
		  		  style: "min-width: 200px;"
		  		},
		  	]
		  },
		  dataToShow() {
		  	let filters = [];

	  		Object.keys(this.filters).forEach( key => {
	  			if ( this.filters[key] && this.filters[key].length > 0 )
	  				filters.push( key )
	  		} );

	  		if ( filters.length == 0 )
	  			return this.labOrders;
	  		
		  	return this.labOrders.filter( order => {
		  		
		  		let orderFilters = [...filters]
		  		
		  		for ( const index in orderFilters )
		  		{
		  			let filterKey = orderFilters[index];

		  			if ( filterKey == 'name' )
		  			{
		  				if ( order.client.name.toLowerCase().includes( this.filters.name.toLowerCase() ) )
		  					orderFilters[index] = true;
		  				else
		  					orderFilters[index] = false;
		  			}

		  			if ( filterKey == 'number' )
		  			{
		  				if ( order.number.toLowerCase().includes( this.filters.number.toLowerCase() ) )
		  					orderFilters[index] = true;
		  				else
		  					orderFilters[index] = false;
		  			}

		  			if ( filterKey == 'matrix' )
		  			{
		  				if ( this.filters.matrix.includes(null) )
		  				{
		  					orderFilters[index] = true;
		  				}
		  				else
		  				{
		  					if ( this.filters.matrix.includes(order.matrix) )
		  						orderFilters[index] = true;
		  					else
		  						orderFilters[index] = false;
		  				}
		  			}

		  			if ( filterKey == 'date' )
		  			{
		  				if ( this.filters.date == order.createdAt.replaceAll('.', '-').split('-').reverse().join('-') )
	  						orderFilters[index] = true;
	  					else
	  						orderFilters[index] = false;
		  			}
		  		}

		  		return orderFilters.reduce((acc, val) => acc &= val, true);

		  	} );
		  },
		  matrixList() {
		  	if ( this.labOrders.length === 0 )
		  		return [{ value: null, text: "Wszystkie" }];

		  	return [
		  		{ value: null, text: "Wszystkie" },
		  		...new Set(this.labOrders.map( el => el.matrix ).filter( el => el != 'n/a' ))
	  		];
		  }
		},
		methods: {
			labOrderResultPrint(id) {
				this.$store.dispatch("resultPrint", {
                    id
                });
			},
			labOrderResultDelete(id, orderId) {
				Swal.fire({
					title: "Czy chcesz usunąć wynik zlecenia?",
					text: "",
					icon: "warning",
					confirmButtonClass: "btn btn-danger",
					cancelButtonClass: "btn btn-primary",
					showCancelButton: true,
					confirmButtonText: "Tak",
					cancelButtonText: "Nie",
				}).then(result => {
					if (result.isConfirmed) {
						this.$store.dispatch("labResultDelete", { 
							"id": id            
						});
						Swal.fire("Gotowe!", "Usunięto wpis.", "success");

						this.$store.dispatch('getOrderResults', {
							id: orderId
						})
					}
				});
			},
			labOrderResultEdit(id, orderId) {
				this.$router.push('/lab/orderResult/' + id + "/edit/" + orderId);
			},
			getOrderResult(id) {
				return this.labResults.filter( el => el.order_id == id );
			},
			labOrderResult(orderId) {
				this.$router.push("/lab/order/"+ orderId +"/result");
			},
			editChart( id, orderId ) {
				this.$router.push("/lab/order/" + orderId + "/chart/" + id);
			},
			getSampleCards( number ) {
				if ( this.charts.length > 0 )
					return this.charts.filter( c => c.samples.includes( number ) );

				return [];
			},
			addChart(id) {
				this.$router.push("/lab/order/" + id + "/chart");
			},
			editLabOrder(id, clientId) {
			  this.$router.push("/orderPanelLab/edit/" + id + "/" + clientId);
			},
			removeLabOrder(id) {
				Swal.fire({
					title: "Czy chcesz usunąć zlecenie?",
					text: "",
					icon: "warning",
					confirmButtonClass: "btn btn-danger",
					cancelButtonClass: "btn btn-primary",
					showCancelButton: true,
					confirmButtonText: "Tak",
					cancelButtonText: "Nie",
				}).then(result => {
					if (result.isConfirmed) {
						this.$store.dispatch("orderLabDelete", {
							id
						});
						Swal.fire("Gotowe!", "Usunięto wpis.", "success");

						this.$store.dispatch("orderLabList");
						this.$store.dispatch(CLIENT_LIST);

	      				this.$router.push("/labl/labOrders");
					}
				});
			},
			handleCollapseSO(id) {
				if ( this.openOrders.includes(id) )
					this.openOrders = [...this.openOrders.filter(el => el != id)]
				else
					this.openOrders.push(id)
			},
			isSOListVisible(id) {
				return this.openOrders.includes(id);
			},
			hadnleCollapseRes(id) {
				if ( this.openResults.includes(id) )
				{
					this.openResults = [...this.openResults.filter(el => el != id)]
					this.$store.commit('removeFromListForOrder', id);
					
				}
				else
				{
					this.$store.dispatch('getOrderResults', {
						id
					})
					this.openResults.push(id)
				}
			},
			isResListVisible(id) {
				return this.openResults.includes(id);
			},
			handleCollapseSample(id) {
				if ( this.openSamples.includes(id) )
				{
					this.openSamples = [...this.openSamples.filter(el => el != id)]
					
				}
				else
				{
					this.openSamples.push(id);
				}
			},
			isSampleListVisible(id) {
				return this.openSamples.includes(id);
			},
			getResearchLineLabel(slug) {
				let candidates = this.researchLinesRaw.filter( line => line.id == slug );

				if ( candidates.length > 0 )
					return candidates[0].parameter + " " + candidates[0].method;
				else
					return ""
			},
			printLabOrder(id, template) {
			  this.$store.dispatch("orderLabPrint", {
			    id,
			    template
			  });
			},
		}
	}
</script>